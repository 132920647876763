define("jared-brosius-2/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ajmSxC8i",
    "block": "[[[3,\" bloc-6 \"],[1,\"\\n\"],[10,0],[14,0,\"bloc l-bloc\"],[14,1,\"bloc-6\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"container bloc-no-padding-lg\"],[12],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"col-lg-5 col-md-3\"],[12],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"col-12 page-scroll col-lg-7 col-md-9\"],[12],[1,\"\\n\\t\\t\\t\\t\"],[10,2],[14,0,\"text-center text-md-start d-xl-none\"],[12],[1,\"Click the menu buttons to learn more.\"],[13],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n            \"],[8,[39,0],null,null,null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[3,\" bloc-6 END \"]],[],false,[\"main-nav\"]]",
    "moduleName": "jared-brosius-2/templates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});