define("jared-brosius-2/controllers/huskertech", ["exports", "masonry-layout"], function (_exports, _masonryLayout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HuskertechController extends Ember.Controller {
    init() {
      super.init(...arguments);
    }

    get columnRender() {
      var elem = document.querySelector('.grid');
      var msnry = new _masonryLayout.default(elem, {
        // options
        itemSelector: '.grid-item',
        gutter: 25,
        fitWidth: true,
        horizontalOrder: false
      });
      var elem = document.querySelector('.grid-2');
      var msnry = new _masonryLayout.default(elem, {
        // options
        itemSelector: '.grid-item',
        gutter: 25,
        fitWidth: true,
        horizontalOrder: false
      });
      var elem = document.querySelector('.grid-3');
      var msnry = new _masonryLayout.default(elem, {
        // options
        itemSelector: '.grid-item',
        gutter: 25,
        fitWidth: true,
        horizontalOrder: false
      });
    }

  }

  _exports.default = HuskertechController;
});