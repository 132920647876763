define("jared-brosius-2/templates/designer/beavercreek", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cDtv5xa8",
    "block": "[[[1,[28,[35,0],[\"Beavercreek\"],null]],[1,\"\\n\"],[46,[28,[37,2],null,null],null,null,null]],[],false,[\"page-title\",\"component\",\"-outlet\"]]",
    "moduleName": "jared-brosius-2/templates/designer/beavercreek.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});