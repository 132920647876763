define("jared-brosius-2/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qgmM+ul/",
    "block": "[[[1,\"\\n\"],[3,\" Primary Meta Tags \"],[1,\"\\n\"],[10,\"title\"],[12],[1,[30,0,[\"model\",\"title\"]]],[13],[1,\"\\n\"],[10,\"meta\"],[14,3,\"title\"],[15,\"content\",[30,0,[\"model\",\"title\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,3,\"description\"],[15,\"content\",[30,0,[\"model\",\"description\"]]],[12],[13],[1,\"\\n\\n\"],[3,\" Open Graph / Facebook \"],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"og:type\"],[14,\"content\",\"website\"],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"og:url\"],[15,\"content\",[30,0,[\"model\",\"url\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"og:title\"],[15,\"content\",[30,0,[\"model\",\"title\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"og:description\"],[15,\"content\",[30,0,[\"model\",\"description\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"og:image\"],[15,\"content\",[30,0,[\"model\",\"image\"]]],[12],[13],[1,\"\\n\\n\"],[3,\" Twitter \"],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"twitter:card\"],[14,\"content\",\"summary_large_image\"],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"twitter:url\"],[15,\"content\",[30,0,[\"model\",\"url\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"twitter:title\"],[15,\"content\",[30,0,[\"model\",\"title\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"twitter:description\"],[15,\"content\",[30,0,[\"model\",\"description\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"twitter:image\"],[15,\"content\",[30,0,[\"model\",\"image\"]]],[12],[13]],[],false,[]]",
    "moduleName": "jared-brosius-2/templates/head.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});