define("jared-brosius-2/templates/artist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NY96nVZy",
    "block": "[[[3,\" bloc-6 \"],[1,\"\\n\"],[10,0],[14,0,\"bloc l-bloc\"],[14,1,\"bloc-6\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"container bloc-no-padding-lg\"],[12],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"col-lg-5 col-md-3\"],[12],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"col-12 page-scroll col-lg-7 col-md-9\"],[12],[1,\"\\n\\n\\t\\t\\t\\t\"],[10,0],[14,0,\"card gray-background-fade\"],[12],[1,\"\\n\\t\\t\\t\\t\\t\"],[10,0],[14,0,\"card-header\"],[12],[1,\"\\n\\t\\t\\t\\t\\t\\t\"],[10,\"h2\"],[14,0,\"font-weight-bold\"],[12],[1,\"Artist\"],[13],[1,\"\\n\\t\\t\\t\\t\\t\"],[13],[1,\"\\n          \"],[10,0],[14,0,\"card-body\"],[12],[1,\"\\n            \"],[10,2],[12],[1,\"More info coming soon.\"],[13],[1,\"\\n          \"],[13],[1,\"\\n\\t\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n            \"],[8,[39,0],null,null,null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[3,\" bloc-6 END \"]],[],false,[\"main-nav\"]]",
    "moduleName": "jared-brosius-2/templates/artist.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});