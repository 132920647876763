define("jared-brosius-2/modifiers/lightcase-init", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function lightcaseInit(element, params, hash) {
    if (element.id == "findit") {
      console.log('findit');
      $('.video').lightcase({
        showCaption: true
      });
      $('.lightcase').lightcase({});
    } else {
      $('a[data-rel^=lightcase]').lightcase({
        showCaption: false
      });
    }
  });

  _exports.default = _default;
});