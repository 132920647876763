define("jared-brosius-2/controllers/designer/index", ["exports", "svg4everybody"], function (_exports, _svg4everybody) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DesignerIndexController extends Ember.Controller {}

  _exports.default = DesignerIndexController;
});