define("jared-brosius-2/controllers/founder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FounderController extends Ember.Controller {
    init() {
      super.init(...arguments);
    }

    willDestroy() {}

  }

  _exports.default = FounderController;
});