define("jared-brosius-2/components/protected-image", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <img ...attributes onclick="return false" oncontextmenu="return false" ondragstart="return false" class="ProtectedImage" style="-webkit-touch-callout:none; -webkit-user-select:none;"/>
  
  */
  {
    "id": "07B/CghM",
    "block": "[[[11,\"img\"],[17,1],[24,\"onclick\",\"return false\"],[24,\"oncontextmenu\",\"return false\"],[24,\"ondragstart\",\"return false\"],[24,0,\"ProtectedImage\"],[24,5,\"-webkit-touch-callout:none; -webkit-user-select:none;\"],[12],[13],[1,\"\\n\"]],[\"&attrs\"],false,[]]",
    "moduleName": "jared-brosius-2/components/protected-image.hbs",
    "isStrictMode": false
  });

  class ProtectedImageComponent extends _component.default {}

  _exports.default = ProtectedImageComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ProtectedImageComponent);
});