define("jared-brosius-2/components/three-wrapper", ["exports", "@glimmer/component", "three"], function (_exports, _component, _three) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div id="three-wrapper" style="z-index:0; position:fixed">
  {{this.threeScene}}
  </div>
  */
  {
    "id": "aH9HYyGt",
    "block": "[[[10,0],[14,1,\"three-wrapper\"],[14,5,\"z-index:0; position:fixed\"],[12],[1,\"\\n\"],[1,[30,0,[\"threeScene\"]]],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "jared-brosius-2/components/three-wrapper.hbs",
    "isStrictMode": false
  });

  let ThreeWrapperComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.reads('fastboot.isFastBoot'), (_class = class ThreeWrapperComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "fastboot", _descriptor2, this);

      _initializerDefineProperty(this, "isFastBoot", _descriptor3, this);

      this.router.on('routeDidChange', transition => {
        console.log(transition.to.name);

        if (transition.to.name == "designer.huskertech") {
          this.colorScene(0xd00000);
        }
      });
    }

    get threeScene() {
      if (this.isFastBoot != true) {
        const SEPARATION = 200,
              AMOUNTX = 300,
              AMOUNTY = 300;
        let container, stats;
        let camera, scene, renderer;
        let particles,
            count = 0;
        const numParticles = AMOUNTX * AMOUNTY;
        const positions = new Float32Array(numParticles * 3);
        const scales = new Float32Array(numParticles);
        const geometry = new _three.default.BufferGeometry();
        let mouseX = 0,
            mouseY = 0;
        let windowHalfX = window.innerWidth / 2;
        let windowHalfY = window.innerHeight / 2;
        init();
        animate();

        function init() {
          container = document.querySelector("#three-wrapper");
          camera = new _three.default.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
          camera.position.z = 1000;
          scene = new _three.default.Scene(); //

          let i = 0,
              j = 0;

          for (let ix = 0; ix < AMOUNTX; ix++) {
            for (let iy = 0; iy < AMOUNTY; iy++) {
              positions[i] = ix * SEPARATION - AMOUNTX * SEPARATION / 2; // x

              positions[i + 1] = 0; // y

              positions[i + 2] = iy * SEPARATION - AMOUNTY * SEPARATION / 2; // z

              scales[j] = 1;
              i += 3;
              j++;
            }
          }

          if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            console.log("darkModeActive");
            colorScene(0xffffff); // dark mode
          } else {
            console.log("lightModeActive");
            colorScene("rgb(62, 67, 75)");
          }

          window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
            const newColorScheme = e.matches ? "dark" : "light";
            console.log(newColorScheme);

            if (newColorScheme == "light") {
              colorScene("rgb(62, 67, 75)");
            } else if (newColorScheme == "dark") {
              colorScene(0xffffff);
            }
          }); //
          //

          renderer = new _three.default.WebGLRenderer({
            antialias: true
          });
          renderer.setPixelRatio(window.devicePixelRatio);
          renderer.setSize(window.innerWidth, window.innerHeight);
          container.appendChild(renderer.domElement);
          container.style.touchAction = 'none';
          window.addEventListener('pointermove', onPointerMove);
          window.addEventListener('touch', onPointerMove); //

          window.addEventListener('resize', onWindowResize);
        }

        function colorScene(pointColorInput) {
          particles = 0;
          var pointColor = new _three.default.Color(pointColorInput);

          if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
            scene.background = new _three.default.Color(0xffffff);
          } else {
            scene.background = new _three.default.Color("rgb(62, 67, 75)");
          }

          geometry.setAttribute('position', new _three.default.BufferAttribute(positions, 3));
          geometry.setAttribute('scale', new _three.default.BufferAttribute(scales, 1));
          const material = new _three.default.ShaderMaterial({
            uniforms: {
              color: {
                value: pointColor
              }
            },
            vertexShader: document.getElementById('vertexshader').textContent,
            fragmentShader: document.getElementById('fragmentshader').textContent
          });
          particles = new _three.default.Points(geometry, material);
          scene.add(particles);
        }

        function onWindowResize() {
          windowHalfX = window.innerWidth / 2;
          windowHalfY = window.innerHeight / 2;
          camera.aspect = window.innerWidth / window.innerHeight;
          camera.updateProjectionMatrix();
          renderer.setPixelRatio(window.devicePixelRatio);
          renderer.setSize(window.innerWidth, window.innerHeight);
        } //


        function onPointerMove(event) {
          if (event.isPrimary === false) return;
          mouseX = event.clientX - windowHalfX;
          mouseY = event.clientY - windowHalfY;
        } //


        function animate() {
          requestAnimationFrame(animate);
          render();
        }

        function render() {
          camera.position.x += (mouseX - camera.position.x) * .05;
          camera.position.y += (-mouseY - camera.position.y) * .05;
          camera.lookAt(scene.position);
          const positions = particles.geometry.attributes.position.array;
          const scales = particles.geometry.attributes.scale.array;
          let i = 0,
              j = 0;

          for (let ix = 0; ix < AMOUNTX; ix++) {
            for (let iy = 0; iy < AMOUNTY; iy++) {
              positions[i + 1] = Math.sin((ix + count) * 0.3) * 25 + Math.sin((iy + count) * 0.5) * 25;
              scales[j] = (Math.sin((ix + count) * 0.3) + 1) * 15 + (Math.sin((iy + count) * 0.5) + 1) * 15;
              i += 3;
              j++;
            }
          }

          particles.geometry.attributes.position.needsUpdate = true;
          particles.geometry.attributes.scale.needsUpdate = true;
          renderer.render(scene, camera);
          count += 0.1;
        }

        console.log('script ran');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isFastBoot", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ThreeWrapperComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ThreeWrapperComponent);
});