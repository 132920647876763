define("jared-brosius-2/controllers/application", ["exports", "ember-notify"], function (_exports, _emberNotify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ApplicationController = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class ApplicationController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "regularNotify", _emberNotify.default.create());

      _defineProperty(this, "iosNotify", _emberNotify.default.create());
    }

    didInsertNode(element) {
      console.log("offline listener started");
      var lost;
      var gained;
      var ios;
      window.addEventListener('offline', () => {
        // Show a "You're offline." toast.
        lost = this.regularNotify.info({
          html: '<p>Your device has lost it&#39;s connection, but you can continue to use the site. New images will not load.</p><p class="mb-0"> Bookmark the <a href="https://www.jaredbrosius.com">homepage</a> to come back even when offline.</p>'
        }, {
          radius: true //rounded corners

        });
      });
      window.addEventListener('online', () => {
        // Clear the "You're offline" toast, if it's open.
        gained = this.regularNotify.success("You're back Online!");
        setTimeout(function () {
          lost.close();
          gained.close();
        }, 5000);
      });
      /*
          var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (isIOS) {
        console.log('This is a IOS device');
        if (window.matchMedia('(display-mode: standalone)').matches) {
          console.log("This is running as standalone.");
        }else{
          console.log("This is not running as standalone.");
          if (this.getCookie("iosInstallBanner") == null){
          setTimeout(this.showiosBanner, 10000);
          }
        }
      }
      */
    }

    showiosBanner() {
      this.ios = this.iosNotify.info({
        html: '<p class="mb-3">This website can be installed as an offline app.</p><p class="mb-0">Tap the "share" icon in the Safari Toolbar, and then "add to homescreen".'
      }, {
        radius: true //rounded corners

      });
      this.setCookie("iosInstallBanner", true);
    }

    setCookie(cname, cvalue) {
      window.localStorage.setItem(cname, cvalue);
    }

    getCookie(cname) {
      const item = window.localStorage.getItem(cname);
      console.log(item);

      if (item != null) {
        return item;
      }

      return null;
    }

    willDestroyNode(element) {
      window.removeEventListener('offline', () => {// Show a "You're offline." toast.
      });
      window.removeEventListener('online', () => {// Clear the "You're offline" toast, if it's open.
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "didInsertNode", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "didInsertNode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showiosBanner", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "showiosBanner"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCookie", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setCookie"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCookie", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "getCookie"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyNode", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyNode"), _class.prototype)), _class));
  _exports.default = ApplicationController;
});