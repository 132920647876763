define("jared-brosius-2/components/button-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LinkTo @route="founder" class="btn btn-clean m-1 m-md-2 btn-light btn-nav ms-md-0">Founder</LinkTo>
  <LinkTo @route="designer.index" class="btn btn-clean m-1 m-md-2 btn-light btn-nav ms-md-0">Designer</LinkTo>
  <LinkTo @route="developer" class="btn btn-clean m-1 m-md-2 btn-light btn-nav ms-md-0">Developer</LinkTo>
  <LinkTo @route="artist" class="btn btn-clean m-1 m-md-2 btn-light btn-nav ms-md-0">Artist</LinkTo>
  */
  {
    "id": "UU+1kWs+",
    "block": "[[[8,[39,0],[[24,0,\"btn btn-clean m-1 m-md-2 btn-light btn-nav ms-md-0\"]],[[\"@route\"],[\"founder\"]],[[\"default\"],[[[[1,\"Founder\"]],[]]]]],[1,\"\\n\"],[8,[39,0],[[24,0,\"btn btn-clean m-1 m-md-2 btn-light btn-nav ms-md-0\"]],[[\"@route\"],[\"designer.index\"]],[[\"default\"],[[[[1,\"Designer\"]],[]]]]],[1,\"\\n\"],[8,[39,0],[[24,0,\"btn btn-clean m-1 m-md-2 btn-light btn-nav ms-md-0\"]],[[\"@route\"],[\"developer\"]],[[\"default\"],[[[[1,\"Developer\"]],[]]]]],[1,\"\\n\"],[8,[39,0],[[24,0,\"btn btn-clean m-1 m-md-2 btn-light btn-nav ms-md-0\"]],[[\"@route\"],[\"artist\"]],[[\"default\"],[[[[1,\"Artist\"]],[]]]]]],[],false,[\"link-to\"]]",
    "moduleName": "jared-brosius-2/components/button-links.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});