define("jared-brosius-2/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EE0DLp/F",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[3,\" Main container \"],[1,\"\\n\"],[10,0],[14,0,\"page-container\"],[12],[1,\"\\n\"],[8,[39,1],null,[[\"@closeAfter\",\"@messageStyle\",\"@source\"],[null,\"bootstrap\",[30,0,[\"regularNotify\"]]]],null],[1,\"\\n\"],[8,[39,1],null,[[\"@closeAfter\",\"@messageStyle\",\"@source\"],[null,\"bootstrap\",[30,0,[\"iosNotify\"]]]],null],[1,\"\\n\"],[11,0],[4,[38,2],[[28,[37,3],[[30,0],[30,0,[\"didInsertNode\"]]],null]],null],[4,[38,4],[[28,[37,3],[[30,0],[30,0,[\"willDestroyNode\"]]],null]],null],[12],[13],[1,\"\\n\"],[8,[39,5],null,null,null],[1,\"\\n\"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n\"],[3,\" ScrollToTop Button \"],[1,\"\\n\"],[10,3],[14,0,\"bloc-button btn btn-d scrollToTop\"],[14,\"onclick\",\"scrollToTarget('1',this)\"],[12],[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"22\"],[14,\"height\",\"22\"],[14,\"viewBox\",\"0 0 32 32\"],[12],[10,\"path\"],[14,0,\"scroll-to-top-btn-icon\"],[14,\"d\",\"M30,22.656l-14-13-14,13\"],[12],[13],[13],[13],[1,\"\\n\"],[3,\" ScrollToTop Button END\"],[1,\"\\n\"],[13],[1,\"\\n\"],[3,\" Main container END \"]],[],false,[\"head-layout\",\"ember-notify\",\"did-insert\",\"action\",\"will-destroy\",\"three-wrapper\",\"component\",\"-outlet\"]]",
    "moduleName": "jared-brosius-2/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});