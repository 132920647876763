define("jared-brosius-2/routes/designer/beavercreek", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DesignerBeavercreekRoute extends Ember.Route {}

  _exports.default = DesignerBeavercreekRoute;
});